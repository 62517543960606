<template>
    <section v-if="secciones.length > 0" :class="clase">
        <div class="content_section">
            <div class="inner">
                <header class="content_header">
                    <h2><span>{{titulo}}<b v-if="profesional == 2">(Esta sección solamente es votada por el jurado)</b></span></h2>
                </header>
                <article class="content_category" :class="getClaseSeccion(secion.id)" v-for="secion in secciones" :key="secion.id">
                    <div class="category_heading heading_link">
                        <h3>{{secion.name ? secion.name : secion.edition}} <span v-if="profesional == 2">(Esta categoría solamente es votada por el jurado)</span></h3>
                        <router-link :to="`/${secion.name ? `seccion/${secion.id}` : `old/${secion.edition}`}`" class="btn xsmall graphic grey right">
                            Ver todo<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                        </router-link>
                    </div>
                    <obra :works="secion.extra ? secion.extras : secion.works" :customRandom="customRandom" :categoria="secion" :old="old ? secion.edition : null"/>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
import Obra from '@/components/home/Obra.vue'
import axios from 'axios'

export default {
    name: "seccion",
    props: {
        titulo: String,
        profesional: Number,
        clase: String,
        extra: Number,
        old: Number,
        customRandom: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            secciones: []
        }
    },
    components:{
        Obra
    },
    created: function(){
        const params = {
            edition: this.extra ? null : localStorage.getItem('edition'),
            extra: this.extra,
            old: this.old,
            works: this.extra ? null : 1,
            active: 1,
            worksLimit: 5,
            worksRandom: this.extra ? null : 1,
            'orderBy[column]': this.extra ? 'actived_at' : null,
            'orderBy[order]': this.extra ? 'desc' : null,
        };
        if(this.profesional == 2){
            params["professional"] = 0;
            params["professional_ac"] = 1;
        }
        else{
            params["professional"] = this.profesional;
            params["professional_ac"] = 0;
        }
        axios
            .get(process.env.VUE_APP_URL + 'categories', {
                params: params
            })
            .then(response => {
                let rs = response.data;
                if(rs.state == 'OK'){
                    this.secciones = rs.data;
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    methods: {
        getClaseSeccion: function(id){
            switch(id){
                case 2:
                    return 'photo_category';
                case 3:
                    return 'story_category';
                case 4:
                    return 'story_category';
                case 5:
                    return 'music_category';
                case 6:
                    return 'audio_story_category';
                case 7:
                    return 'illustration_category';
                case 12:
                    return 'reel_category';
                default:
                case 1:
                case 8:
                case 9:
                case 10:
                    return 'video_category';
            }
        }
    }
}
</script>